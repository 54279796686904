<template>
	<div>
		<Header2022 :navbg='true'></Header2022>
		<div class="content">
			<Banner :bannerList="bannerList" :showtitle="showtitle" class="banner"></Banner>

			<section class="homepage-grids">
				<div class="homepage-top">
					<div class="news">
						<div class="hot" v-if="hotArticle" @click="articleTap(hotArticle)">
							<img :src="hotArticle.image">
							<div class="hotTitle">{{hotArticle.title}}</div>
						</div>
						<div class="newsList" v-if="newArticle">
							<div class="item-wrap" v-for="(item,index) in newArticle" :key="index" @click="articleTap(item)">
								<div class="tile-media">
									<img :src="item.image">
								</div>
								<div class="tile-content-text">
									<p class="tile-content-headline">{{item.title}}</p>
									<div class="info">
										<span class="time">{{item.createtime}}</span>
										<span class="count">
											<img src="../../assets/images/icon-eye.svg">
											<i>{{item.views}}</i>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="cell">
						<div class="product-grid-item ga-data" @click="toolClick('productLifehall')">
							<img class="grid-bg" src="../../assets/images/index/index1.jpg">
							<div class="grid-content">
								<img class="grid-logo life-house" src="../../assets/images/index/living-house.svg">
								<p class="grid-desc">悦享品质第三空间</p>
							</div>
						</div>

						<div class="product-grid-item ga-data" @click="linkClick('http://mall.xiaoyuan.com.cn/')">
							<img class="grid-bg" src="../../assets/images/index/index2.jpg">
							<div class="grid-content">
								<img class="grid-logo store-logo" src="../../assets/images/index/online.svg">
								<p class="grid-desc">合适才适合</p>
							</div>
						</div>

					</div>
				</div>
				<div class="homepage-bottom">
					<div class="service-list">

						<div class="service-item ga-data" @click="toolClick('offlineExperience')">
							<h3 class="grid-title">线下体验</h3>
							<p class="grid-desc">查找您附近的小院住宅产品</p>
						</div>

						<div class="service-item ga-data" @click="toolClick('onlineExperience')">
							<h3 class="grid-title">线上体验</h3>
							<p class="grid-desc">使用VR技术 感知经典户型</p>
						</div>

						<div class="service-item ga-data" @click="linkClick('http://mall.xiaoyuan.com.cn/index.php?ctl=designs')">
							<h3 class="grid-title">3D云设计</h3>
							<p class="grid-desc">零门槛，自己动手设计你的家</p>
						</div>

					</div>
				</div>
			</section>
		</div>
		<Footer2022></Footer2022>
	</div>
</template>

<script>
	import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
	import Banner from '@/components/banner.vue'
	import {
		mapState
	} from 'vuex'
	import utils from '@/common/utils'

	export default {
		data() {
			return {
				bannerList: [],
				showtitle: true,
				channel: null,//所有分类集合
				hotArticle:null,
				newArticle:null,//最新上传时间排行
			}
		},
		components: {
			Banner,
			Header2022,
			Footer2022,
		},
		computed: {
			...mapState(['ocApi', 'newsApi']),
		},
		created() {
			this.getBanner();
			this.getNewsClass()
		},
		methods: {
			getBanner: function() {
				let that = this;
				that.$axios.all([
						that.$axios.get(that.ocApi + 'book/banner', {
							params: {
								'name': 'pc_index_banner_slide',
							}
						}),
					])
					.then(that.$axios.spread(function(banner_slide, bbs) {

						if (banner_slide.data.status == 1) {
							that.bannerList = banner_slide.data.data.images
						} else {
							that.$message.error(banner_slide.data.message);
						}

					}));
			},
			getArticleList: function(flag) {
				let that = this;
				that.$axios.post(that.newsApi + 'index/index', {
					channel: that.channel,
					page: 1,
					flag
				})
				.then(res => {
					if (res.data.code == 1) {
						if(flag=='hot'){
							that.hotArticle=res.data.data.data[0];
						}else{
							that.newArticle=res.data.data.data.slice(0,2);
						}
					}else{
						that.$message.error(res.data.msg);
					}
				})
			},
			getNewsClass() {
				let that = this;
				that.$axios.get(that.ocApi + 'appmanage/dictionary', {
					params: {
						code: 'web_news'
					}
				})
				.then(res => {
					let data = res.data.data;
					let dataArr = [];
					for (const item of data) {
						dataArr.push(item.value);
					}
					that.channel = dataArr.join(',');
					that.getArticleList('hot');
					that.getArticleList('');
				});
			},
			articleTap: utils.debounce(function(item) {
				this.$router.push({
					name: 'newsDetail',
					params: {
						id: item.id,
						paramsName: this.channel
						
					}
				});
			}, 200, true),
			toolClick: utils.debounce(function(name) {
				this.$router.push({
					name
				})
			}, 200, true),
			linkClick: utils.debounce(function(url) {
				window.open(url)
			}, 200, true),
		},
	}
</script>

<style lang="scss" scoped>
	/*main*/
	section.homepage-grids {
		background-color: #f2f2f2;
	}

	.homepage-top {
		width: 1200px;
		margin: 0 auto;
		padding-top: 40px;
		display: flex;
	}

	.homepage-top .news,
	.homepage-top .banner,
	.homepage-top .cell {
		flex: 1;
		width: 50%;
		overflow: hidden;
		height: 660px;
		cursor: pointer;
	}

	.news {
		.hot {
			width: 590px;
			height: 388px;
			position: relative;

			img {
				width: 100%;
				height: 100%;
			}

			.hotTitle {
				width: 100%;
				height: 65px;
				background: #000000;
				opacity: 0.5;
				font-size: 24px;
				color: #FFFFFF;
				line-height: 64px;
				text-align: center;
				position: absolute;
				bottom: 0;
				right: 0;
				overflow: hidden;
				text-overflow:ellipsis;
				white-space: nowrap;
				padding: 0 5px;
				box-sizing: border-box;
			}
		}

		.newsList {
			width: 590px;
			height: 272px;
			background: #FFFFFF;
			padding: 0px 20px;
			box-sizing: border-box;

			.item-wrap {
				height: 135px;
				display: flex;
				padding: 16px 0 12px;
				box-sizing: border-box;

				&:first-child {
					border-bottom: 1px solid #DDDDDD;
				}

				.tile-media {
					width: 30%;

					img {
						width: 100%;
						height: 100%;
					}
				}

				.tile-content-text {
					margin-left: 4%;
					width: 66%;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					padding: 5px 0 1px;
					box-sizing: border-box;
					.tile-content-headline {
						font-size: 24px;
						color: #666666;
						line-height: 34px;
						
						display: -webkit-box;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						overflow: hidden;
						word-wrap: break-word;
					}

					.info {
						display: flex;
						justify-content: space-between;
						
						.time {
							font-size: 12px;
							color: #666666;
							line-height: 23px;
						}

						.count {
							img {
								width: 13px;
								height: 10px;
							}

							i {
								font-size: 12px;
								color: #666666;
								line-height: 23px;
								margin-left: 5px;
							}
						}
					}
				}
			}
		}

	}

	.list-item {
		position: relative;
	}
	h3.grid-title {
		font-size: 24px;
		font-weight: 500;
		line-height: 32px;
	}

	p.grid-desc {
		font-size: 16px;
		font-weight: 300;
		line-height: 24px;
	}

	.cell {
		display: flex;
		flex-direction: column;
		margin-left: 10px;
	}

	.product-grid-item {
		display: block;
		width: 100%;
		height: 320px;
		position: relative;
		overflow: hidden;
	}

	.homepage-top .product-grid-item:first-child {
		margin-bottom: 20px;
		color: #fff;
	}

	img.grid-bg {
		width: 100%;
		height: 100%;
		background-position: center center;
		background-size: auto 320px;
		transition: all 0.5s;
		z-index: 300;
	}

	.product-grid-item:hover img.grid-bg {
		transform: scale(1.03);
	}

	.product-grid-item .grid-content {
		position: absolute;
		padding-left: 40px;
		top: 40%;
		z-index: 400;
		transition: all 0.5s;
	}

	.product-grid-item:hover .grid-content {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}

	img.grid-logo {
		display: inline-block;
		height: 26px;
		margin-bottom: 2px;
	}

	img.life-house {
		height: 30px;
	}

	img.store-logo {
		height: 56px;
	}

	p.grid-desc {
		font-size: 16px;
		font-weight: 300;
		line-height: 24px;
	}

	.homepage {
		padding-top: 40px;
		width: 1200px;
		margin: 0 auto;
	}

	.homepage .homepage-cell {
		width: 100%;
		display: flex;
	}

	.homepage .homepage-cell .product-grid-item:nth-of-type(1) {
		margin-right: 10px;
	}

	.homepage .homepage-cell .product-grid-item:nth-of-type(2) {
		margin-left: 10px;
	}

	.homepage .homepage-cell .product-grid-item {
		height: 296px;
		position: relative;
	}

	.homepage .homepage-cell .product-grid-item .grid-content {
		position: absolute;
		top: 205px;
		left: 40px;
	}

	.homepage-bottom {
		width: 1200px;
		margin: 0 auto;
		padding-bottom: 64px;
		padding-top: 40px;
	}

	.homepage-bottom .service-list {
		background: #fff;
		display: flex;
	}

	.homepage-bottom .service-list .service-item {
		position: relative;
		display: inline-block;
		padding: 55px 40px;
		border-left: 1px solid #f2f2f2;
		color: #303233;
		width: 100%;
		cursor: pointer;
		transition: all 0.4s;
		flex: 1;
	}

	.homepage-bottom .service-list .service-item:before {
		display: block;
		content: '';
		position: absolute;
		width: 3px;
		height: 46px;
		background-color: #c2c8cc;
		left: 0;
		top: 50%;
		margin-top: -23px;
		border-top-right-radius: 3px;
		border-bottom-right-radius: 3px;
		transition: all 0.4s;
	}

	.homepage-bottom .service-list .service-item:hover:before {
		background-color: #303233;
	}
</style>